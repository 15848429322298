<template>
    <CRow>
        <CCol col="12" lg="12">
            <CCard>
                <CCardBody>
                    <form
                        @submit.prevent="createRecord()"
                        method="post"
                    >
                        <h3 v-if="isEditing">
                            {{ $t('button.edit') }} {{ $t('employees.title') }}
                        </h3>
                        <h3 v-else>{{ $t('button.create') }} {{ $t('employees.title') }}</h3>
                        <br>
                        <CRow>
                            <CCol col="12" lg="12">
                                <CSelect 
                                    :placeholder="$t('categories.placeholder')" 
                                    :label="$t('categories.title')" 
                                    :options="categories"
                                    :value.sync="field.category_id"
                                    :plain="true"
                                ></CSelect>
                            </CCol>
                            <CCol col="12" lg="12">
                                <CInput 
                                    :placeholder="$t('fields.name.placeholder')" 
                                    :label="$t('fields.name.label')" 
                                    v-model="field.name" 
                                    type="text" 
                                    required
                                ></CInput>
                            </CCol>
                            <CCol col="12" lg="12">
                                <CInput 
                                    :placeholder="$t('fields.phone.placeholder')" 
                                    :label="$t('fields.phone.label')" 
                                    v-model="field.phone" 
                                    type="text" 
                                ></CInput>
                            </CCol>
                            <CCol col="12" lg="12">
                                <CInput 
                                    :placeholder="$t('fields.salary.placeholder')" 
                                    :label="$t('fields.salary.label')" 
                                    v-model="field.salary" 
                                    type="text" 
                                ></CInput>
                            </CCol>
                       </CRow>
                       <CCard>
                           <CCardBody>
                               <h3>Archivos del usuario</h3>
                               <br>
                               <CRow v-for="item of field.files" v-bind:key="item.id">
                                   <CCol col="5" lg="5">
                                       <CInput 
                                           :placeholder="$t('fields.name.placeholder')" 
                                           :label="$t('fields.name.label')" 
                                           v-model="item.name_file" 
                                           type="text" 
                                           required
                                       ></CInput>
                                   </CCol>
                                   <CCol col="5" lg="5">
                                        <br/>
                                       <input type="file" @change="handleFiles($event, item.id)"/>
                                   </CCol>
                                   <CCol col="2" lg="2">
                                       <br/>
                                        <CButtonGroup>
                                            <CButton color="success" @click="showFile(item.id)"><CIcon :content="showIcon" /></CButton>
                                            <CButton color="warning" @click="deleteFile(item.id)"><CIcon :content="deleteIcon" /></CButton>
                                        </CButtonGroup>
                                   </CCol>
                               </CRow>
                               <CRow>
                                   <CCol col="12" lg="12" class="text-right">
                                       <CButton color="primary" @click="addFile" class="m-2">
                                           <CIcon :content="fileIcon" /> Agregar Archivo
                                       </CButton>
                                   </CCol>
                               </CRow>
                           </CCardBody>
                       </CCard>
                        <CRow>
                            <CCol col="12" lg="12" class="text-right">
                                <CButton color="success" type="submit" class="m-2">
                                    <template v-if="isEditing">
                                        <CIcon :content="updateIcon" /> {{ $t('button.update') }}
                                    </template>
                                    <template v-else>
                                        <CIcon :content="addIcon" /> {{ $t('button.create') }}
                                    </template>
                                </CButton>
                                <CButton color="primary" to="/employees" class="m-2">
                                    <CIcon :content="backIcon" /> {{ $t('button.back') }}
                                </CButton>
                            </CCol>
                        </CRow>
                    </form>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios'
import { cilSync, cilPlus, cilArrowLeft, cilFile, cilDescription, cilDelete } from '@coreui/icons';

export default {
    name: 'EmployeeCreate',
    data() {
        return {
            field: {
                name: '',
                phone: '',
                files: [],
                salary: '',
                category_id: '',
                required: false,
            },
            categories: [],
            addIcon: cilPlus,
            fileIcon: cilFile,
            updateIcon: cilSync,
            deleteIcon: cilDelete,
            backIcon: cilArrowLeft,
            showIcon: cilDescription,
        }
    },
    computed: {
        isEditing() {
            return Boolean(this.$route.params.id);
        }
    },
    methods: {
        createRecord() {

            let formData = new FormData();
            formData.append('name', this.field.name);
            formData.append('phone', this.field.phone);
            formData.append('salary', this.field.salary);
            formData.append('category_id', this.field.category_id);


            this.field.files.forEach(element => {
                formData.append('id['+element.id+']', element.id);
                formData.append('name_file['+element.id+']', element.name_file);
                formData.append('files['+element.id+']', element.file);
            });

            axios.post(`api/employees${this.isEditing ? "/"+this.$route.params.id : ""}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.$router.push(`/employees`);
                    this.$toast.success(this.isEditing ? i18n.t('success_modify') : i18n.t('success_create'));
                }).catch(error => {
                    if (error.response.status === 401) {
                        this.$router.push({ path: '/login' });
                    } else {
                        this.$toast.error(error);
                    }
                });
        },
        handleFiles(event, fileId) {
            let self = this;
            let file = self.field.files.find(element => element.id == fileId);
            file.file = event.target.files[0];
        },
        addFile() {
            let self = this;

            if (!self.field.files) {
                self.field.files = [];
            }

            self.field.files.push({
                file: null,
                name_file: "",
                id: self.field.files.length,
            });
        },
        deleteFile(fileId) {
            let self = this;
            let file = self.field.files.find(element => element.id == fileId);
            let index = self.field.files.indexOf(file);
            self.field.files.splice(index, 1);
        },
        showFile(fileId) {
            let self = this;
            let file = self.field.files.find(element => element.id == fileId);

            Object.assign(document.createElement("a"), {
                target: "_blank",
                rel: "noopener noreferrer",
                href: file.file,
            }).click();

            console.log(file);
        }
    },
    mounted() {
        let self = this;

        axios.get(`api/categories`)
            .then(response => {
                response.data.data.forEach(function(record){
                    self.categories.push({
                        value: record.id,
                        label: record.name,
                    });
                });
            }).catch(error => {
                if (error.response.status === 401) {
                    self.$router.push({ path: '/login' });
                } else {
                    self.$toast.error(error.response.data.message);
                }
            });

        if (this.isEditing) {
            axios.get(`api/employees/${this.$route.params.id}`)
                .then(response => {
                    if (!('files' in response.data.data)) {
                        response.data.data["files"] = [];
                    }

                    self.field = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        self.$router.push({ path: '/login' });
                    } else {
                        self.$toast.error(error.response.data.message);
                    }
                });
        }     
    }
}
</script>
